import { template as template_ab057dc782374510a2db8f4d0af4d261 } from "@ember/template-compiler";
const FKLabel = template_ab057dc782374510a2db8f4d0af4d261(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
