import { template as template_5902b64e72344f66b4e93a43e008d248 } from "@ember/template-compiler";
const FKText = template_5902b64e72344f66b4e93a43e008d248(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
