import { template as template_a47f636a404346b88ee574721080dadd } from "@ember/template-compiler";
const WelcomeHeader = template_a47f636a404346b88ee574721080dadd(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
